import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { useCard, useCards } from "../../../../utils/reactQuery/cards";
import CustomizedDialogs from "../details";

const OpenCardDetail = ({ cardsOnBoard = [] }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [cards, setCards] = useState([]);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const cardId = searchParams.get("card_id");
  // console.log("CARDDETAILS...",data)
  // console.log("title",data.title);
  const { boardName } = useBoardName(id);
  const boardNameTitle = boardName?.data?.data?.responseData?.board_name
    .substring(0, 3)
    .toUpperCase();

  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const cardWorkflowStepId = card?.data?.data?.responseData?.workflow_step_id;
  console.log("cardWorkflowStep", cardsOnBoard);
  // const [cards, setCards] = useState(cardsOnBoard ? cardsOnBoard.filter((item)=>item?.workflow_step_id == cardWorkflowStepId) : [])
  // console.log("cards",cards,cardId)
  useEffect(() => {
    if (cardId) {
      setCards(
        cardsOnBoard.filter(
          (item) => item?.card.workflow_step_id == cardWorkflowStepId
        )
      );
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [cardId, cardsOnBoard, cardWorkflowStepId]);

  console.log("mine cards array", cards);
  return (
    <div>
      {cardId && cards && (
        <CustomizedDialogs
          //   status={status}
          boardNameTitle={boardNameTitle}
          cardId={cardId}
          boardId={id}
          cardsOfSameWorkflowStepId={cards}
          // title={project.title}
          // cardId={cardId}
          // description={project.description}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      )}
    </div>
  );
};

export default OpenCardDetail;
