import React, { useRef } from "react";
import KanbanColumn from "./KanbanColumn";

function KanbanContainor({
  workflowSteps,
  projects,
  onDragEnter,
  onDragEnd,
  callback,
}) {
  const boardRef = useRef(null);

  const handleOnDrag = (e) => {
    const board = boardRef.current;
    if (!board) return;

    const { clientX, clientY } = e;
    const buffer = 50; // Distance from the edge of the container to start scrolling
    const scrollSpeed = 25; // Speed of the scroll

    // Horizontal scroll
    if (clientX - board.offsetLeft < buffer) {
      board.scrollBy({ left: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetLeft + board.clientWidth - clientX < buffer) {
      board.scrollBy({ left: scrollSpeed, behavior: "smooth" });
    }

    // Vertical scroll
    if (clientY - board.offsetTop < buffer) {
      board.scrollBy({ top: -scrollSpeed, behavior: "smooth" });
    } else if (board.offsetTop + board.clientHeight - clientY < buffer) {
      board.scrollBy({ top: scrollSpeed, behavior: "smooth" });
    }
  };

  return (
    // <div className="board-card-scroll-btn  d-flex ">
    <div
      // className="board-card-scroll-btn d-flex "
      ref={boardRef}
      style={{
        display: "flex",
        // width: "500px",
        overflowX: "scroll",
        // height: "500px",
        // overflowY: "scroll",
      }}
      onDragOver={handleOnDrag}
    >
      {workflowSteps.map((column) => (
        <KanbanColumn
          callback={callback}
          key={column.workflow_step_id}
          id={column.workflow_step_id}
          name={column.status}
          stage={column.workflow_step_id}
          projects={projects.filter(
            (project) =>
              parseInt(project.card.workflow_step_id, 10) ===
              column.workflow_step_id
          )}
          onDragEnter={onDragEnter}
          onDragEnd={onDragEnd}
        />
      ))}
    </div>
  );
}

export default KanbanContainor;
