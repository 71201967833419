import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill styles

const WorklogText = ({ text }) => {
  const editorRef = useRef(null);
  function convertTextWithUrlsToHtml(text) {
    // Regular expression to match URLs
    const urlPattern = /https?:\/\/[^\s<>"']+/g;

    // Split the text into segments of HTML tags and non-tag content
    const segments = text.split(/(<\/?[^>]+>)/);

    // Process each segment to convert URLs to anchor tags
    const htmlText = segments
      .map((segment) => {
        if (segment.startsWith("<") || segment.startsWith("</")) {
          return segment; // Preserve HTML tags
        } else {
          return segment.replace(urlPattern, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`;
          });
        }
      })
      .join("");

    return htmlText;
  }

  useEffect(() => {
    const quill = new Quill(editorRef.current, {
      theme: "snow",
      readOnly: true, // Make the editor read-only
      modules: {
        toolbar: false, // Hide the toolbar
      },
    });
    const cleanedText = text && text.replace(/(?<!\n)\n(?!\n)/g, "");

    // Set the content of the Quill editor with the text from the API
    if (text) {
      quill.root.innerHTML = convertTextWithUrlsToHtml(cleanedText);
    }
  }, [text]);

  return <div id="editor2" ref={editorRef}></div>;
};

export default WorklogText;
