import React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Badge, Avatar as Muiavatar } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/base";
// import Avatar from "../../../../../../components/Avatar"
import Avatar from "../../../../components/Avatar";
import Loader from "../../../../components/Loader";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import "./worklog.scss";
import { deleteWorklog } from "../../../../sevices/apiWorklog";
// import {localStorageInstance} from "../../../../../../utils/localStorage"
import { localStorageInstance } from "../../../../utils/localStorage";
// import { deleteComment } from "../../../../../sevices/apiComment";
import CustomDateFormatter from "../dateTimeFormateConverter/dateTimeFormateConverter";
import UpdateWorklogCustomizedDialogs from "../worklog/addWorklog/addWorklogDialog"
import EditWorklog from '../../components/worklog/editWorklog/editWorklog'
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { useParams } from "react-router-dom";
import { useCard } from "../../../../utils/reactQuery/cards";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import GetHeadShotName from "../../../../utils/getHeadShotName";
import { hasImage,compareDates } from "../../../../utils/helpers";
import ReadOnlyDescription from "../details/detailGrid/description/ReadOnlyDescription";
import WorklogText from "./worklogTextEditor";
import warningIcon from '../../../../styles/svg/warningIcon.svg'
import WorklogIcon from "../../../../styles/svg/woklog-icon.svg";

const SmallAvatar = styled(Muiavatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// import GetHeadShotName from "../getHeadShotName/getHeadShotName";
function ListWorklog({ data, cardId, showIcon = false }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  // // const [messg, setMessg] = useState(null);
  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const cardData = card?.data?.data?.responseData;

  const user = useSelector((state) => state.users.logInUser);
  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  console.log("CARDDATE", data);
  // const headShotName = `${cardData?.assigned_to_user?.first_name[0] || ""}${cardData?.assigned_to_user?.last_name[0] || ""}`

  //   function GetHeadShotName(userName) {
  //     // Split the username by space
  //     const parts = userName.split(' ');

  //     // Check if there are at least two parts (first name and last name)
  //     if (parts.length >= 2) {
  //         const firstInitial = parts[0].charAt(0).toLowerCase();
  //         const secondInitial = parts[1].charAt(0).toLowerCase();
  //         return firstInitial + secondInitial;
  //     }

  //     // If there is no space, return the first character of the username
  //     return userName.charAt(0).toLowerCase();
  // }

  const headShotName = GetHeadShotName(data?.username);

  const correctListTypes = (html) => {
    return html.replace(
      /<ol>\s*(<li[^>]*data-list="bullet"[^>]*>.*?<\/li>\s*)+<\/ol>/g,
      (match) => {
        return match.replace(/<ol>/g, "<ul>").replace(/<\/ol>/g, "</ul>");
      }
    );
  };

  function replaceSpacesInPTags(html) {
    // Regular expression to match space inside <p> tags
    const regex = /<p>(.*?)<\/p>/g;

    // Replace space with &nbsp; inside <p> tags
    const replacedHtml = html.replace(regex, (match, content) => {
      const replacedContent = content.replace(/ /g, "&nbsp;");
      return `<p>${replacedContent}</p>`;
    });

    return replacedHtml;
  }

  const { mutate: deleteWorklogMutate } = useMutation({
    mutationFn: deleteWorklog,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`worklog`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleClose();
    },
  });

  const handleEdit = () => {
    setShowEdit(true);
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  function callback() {
    setLoading(true);
    // console.log(data.card_log_id);
    deleteWorklogMutate(data.card_log_id);
  }

  return (
    <>
      {showDelete && (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleClose}
          message={
            "Are you sure to you want to delete this item? This action can not be undone"
          }
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete confirmation"}
        />
      )}
      {showEdit && (
        <EditWorklog
          card_log_id={data.card_log_id}
          cardId={cardId}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
        />
        //  <UpdateWorklogCustomizedDialogs
        //   cardId={cardId}
        //    showEdit={showEdit}
        //    setShowEdit={setShowEdit}

        //   heading={"Update Work Log"}
        //   buttonName={"Update"}
        // />
      )}
      <Box sx={{ flexGrow: 1, px: 2, width: "400px" }}>
        <Grid container spacing={2}>
          <div className="worklog-box">
            <Grid>
              {showIcon ? (
                <Badge
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src={WorklogIcon} />
                  }
                >
                  <Avatar
                    title={data.username}
                    className={" header-avatar worklog-box-img"}
                    user_image={data.user_image}
                    style={
                      !hasImage && {
                        bgcolor: data?.user_profile_color?.background,
                        color: data?.user_profile_color?.text,
                      }
                    }
                  >
                    {headShotName}
                  </Avatar>
                </Badge>
              ) : (
                  <Avatar
                    title={data.username}
                    className={" header-avatar worklog-box-img"}
                    user_image={data.user_image}
                    style={
                      !hasImage && {
                        bgcolor: data?.user_profile_color?.background,
                        color: data?.user_profile_color?.text,
                      }
                    }
                  >
                    {headShotName}
                  </Avatar>
               
              )}
              {/* <Avatar className="worklog-box-img">{data.user_image}</Avatar> */}
            </Grid>
            <div className="worklog-box-body">
              <div className="d-flex justify-content-space-between">
                <Grid className="worklog-detail">
                  <Typography className="worklog-box-name">
                    {data.username}
                    <span className="worklog-status">logged</span>
                  </Typography>
                  <Typography className="worklog-box-hours">
                    {`${data.hour_spent} hours`}
                  </Typography>
                </Grid>

                {/* <Grid>
                  <Typography className="worklog-box-hours">
                    {`${data.hour_spent} hours`}
                  </Typography>
                </Grid> */}

                <Grid>
                  <Typography className="worklog-box-date ">
                    <div className="d-flex align-items-center justify-content-end">
                      {!compareDates(data.created_at, data.worklog_time) && (
                        <div className="warningicon">
                          {" "}
                          <img src={warningIcon} alt="" />{" "}
                        </div>
                      )}
                      <div className="worklog-box-date-field ml-1">
                        <CustomDateFormatter date={data.worklog_time} />
                      </div>
                    </div>
                    <div className="created-date-worklog">
                      Created{" "}
                      {<CustomDateFormatter date={data?.date_created} />}
                    </div>
                    {/* {data.worklog_time} */}
                  </Typography>
                </Grid>
              </div>
              <Grid>
                {/* <Typography
                  className="worklog-box-message"
                  dangerouslySetInnerHTML={{ __html: correctListTypes(data.work_performed) }}
                /> */}

                <WorklogText text={data.work_performed} />
              </Grid>
              {cardData?.assigned_to_user_id == currentUserId() && (
                <div>
                  <Button
                    className="worklog-box-btn1"
                    onClick={() => handleEdit()}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    className="worklog-box-btn2"
                  >
                    Delete
                  </Button>
                </div>
              )}

              {/* <Grid>
                <span className="worklog-box-name">Date created:</span> <span className="worklog-box-date"><CustomDateFormatter date={data.created_at} /></span> 
              </Grid> */}
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}
// import CustomDateFormatter from '../dateTimeFormateConverter/dateTimeFormateConverter';

export default ListWorklog;
