import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EditEpic from "./EditEpic";
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  Select,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import apicIcon from "../../../../../styles/svg/updt_milestone.svg";
import CreateEpic from "./CreateEpic";
import "./epicbutton.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { useEpic } from "../../../../../utils/reactQuery/epics";
import { searchCard } from "../../../../../redux/EpicSearchSlice";
import { useBoardName } from "../../../../../utils/reactQuery/boards";
// import EditIcon from '../../../../styles/svg/edit-title.svg'
import EditIcon from "../../../../../styles/svg/edit-title.svg";
import AddMilestone from "../../../../../components/Milestone/addMilestone";
import openMilestone from "../../../../../styles/svg/open-milestone.svg";
import EditMIlestone from "../../../../../components/Milestone/EdiMilestone";
import { isAdmin } from "../../../../../utils/constants";

const EpicButton = ({boardNameDetails}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [epic, setEpic] = useState(
    searchParams.get("milestone")
      ? searchParams
          .get("milestone")
          .split(",")
          .filter((el) => el)
          .map((el) => el * 1)
      : []
  );
  const [editMileStonePop, setEditMileStonePop] = useState(false);
  const [slectedMilestone, setSelectedMilestone] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showEditArea, setShowEditArea] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const epicUrl = searchParams.get("milestone");

  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const boardNameTitle = boardName?.data?.data?.responseData?.board_name
    .substring(0, 3)
    .toUpperCase();

  const { epics } = useEpic(id);

  const names = epics?.data?.data?.responseData || [];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleCheckboxChange = (id) => {
    // setChecked((prev)=>!prev)
    setEpic((prevEpic) => {
      if (prevEpic.includes(id)) {
        updateSearchParams(
          "milestone",
          prevEpic.filter((n) => n !== id)
        );
        return prevEpic.filter((n) => n !== id);
      } else {
        updateSearchParams("milestone", [id]);
        return [id];
      }
    });
  };

  const handleSelectAll = () => {
    // setChecked((prev)=>!prev)
    if (epic.length === names.length) {
      setEpic([]);
      updateSearchParams("milestone", []);
    } else {
      setEpic(names.map((name) => name.epic_id));
      updateSearchParams(
        "milestone",
        names.map((name) => name.epic_id)
      );
    }
    // dispatch(searchCard(epic))
  };

  const isSelectAllChecked = epic.length === names.length;

  useEffect(() => {
    const data = searchParams
      .get("milestone")
      ?.split(",")
      ?.filter((el) => el)
      ?.map((el) => el * 1);
    dispatch(searchCard(data || []));
    setEpic(data || []);
  }, [epicUrl]);

  const showEditButton = (epic_id) => {
    setHoveredIndex(epic_id);
  };
  const handleShowEditArea = (id) => {
    // setShowEditArea(true);
    setSelectedMilestone(id);
    setEditMileStonePop(true);
  };
  const handleOpenMilestone = (idx) => {
    names.map((ele, index) => {
      if (index == idx) {
        updateSearchParams("milestone", ele?.epic_id);
      }
    });
  };
  const handleEditFieldClose = () => {
    setShowEditArea(false);
    setHoveredIndex(null);
  };
  // console.log("EPIC",personName)
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className="white-button epics-btn"
        sx={{
          height: "38px",
          padding: "5px 10px 5px 2px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}>
        <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "}
        &nbsp;{" "}
        <p className="header-action-button-text" style={{ width: "20px" }}>
          All
        </p>
        {/* {epic.length ? (
          <span className="coloumn-header-length">{epic.length}</span>
        ) : (
          ""
        )} */}
      </Button>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: "350px",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        // className="epic-list"
      >
        <div style={{ maxHeight: "222px", overflowY: "auto" }}>
          {/* <MenuItem
            // onClick={handleSelectAll}
            sx={{
              height: "30px", // Adjust the height as needed
              minHeight: "36px", // Ensure minimum height is set
            }}>
            {/* <Checkbox  checked={isSelectAllChecked} indeterminate={epic.length > 0 && epic.length < names.length} />
          <ListItemText  className='listItem-text'  primary="Select All Epics" /> */}

          {/* <label class="custom-checkbox major-div-select-All">
              <input
                type="checkbox"
                onClick={handleSelectAll}
                // id={`epic-list-checkbox-${index}`}
                checked={isSelectAllChecked}
                indeterminate={epic.length > 0 && epic.length < names.length}
              />
              <span class="checkmark"></span>
              <div className="epic-name-id-div-select-All">
                <p className="epic-name">Select All Epics</p>
              </div>
            </label> */}
          {/* </MenuItem>
          <Divider />  */}
          {names?.map((item, index) => (
            <React.Fragment key={item?.epic_id}>
              <MenuItem
                sx={{
                  height: "40px", // Adjust the height as needed
                  minHeight: "36px", // Ensure minimum height is set
                  padding: "12px 8px !important ",
                }}
                onMouseEnter={() => showEditButton(item?.epic_id)}
                onMouseLeave={handleEditFieldClose}>
                {/* <Checkbox className='epic-checkbox' checked={epic.indexOf(item?.epic_id) > -1} />
              <div className="epic-list">
              <ListItemText className='listItem-text'  primary={item?.epic_name} />
                <p>{`${boardNameTitle} - ${item?.epic_id}`}</p>
                </div> */}

                <label class="custom-checkbox major-div">
                  <input
                    type="checkbox"
                    onClick={() => handleCheckboxChange(item?.epic_id)}
                    id={`epic-list-checkbox-${index}`}
                    checked={epic.indexOf(item?.epic_id) > -1}
                  />
                  <span class="checkmark"></span>
                  <div className="epic-name-id-div">
                    <p className="epic-name">
                      {showEditArea && hoveredIndex === item.epic_id ? (
                        <EditEpic
                          epicId={item.epic_id}
                          epicName={item?.epic_name}
                          setShowEditArea={setShowEditArea}
                        />
                      ) : (
                        item?.epic_name
                      )}
                    </p>
                    <p className="epic-id">
                      {!showEditArea && `${boardNameTitle} - ${item?.epic_id}`}
                    </p>
                  </div>
                </label>
                {/* {hoveredIndex === item.epic_id && (
                  <>
                    <img
                      src={EditIcon}
                      onClick={() => handleShowEditArea(index)}
                      alt="edIt iCon"
                    />
                    <img
                      src={openMilestone}
                      onClick={() => handleOpenMilestone(index)}
                      alt="open milestone"
                      style={{ marginLeft: "10px", marginRight: '5px'}}
                    />
                  </>
                )} */}
              </MenuItem>
              {index < names.length - 1 && <Divider />}
            </React.Fragment>
          ))}
          <EditMIlestone
            open={editMileStonePop}
            handleClose={() => setEditMileStonePop(false)}
          />
          {isAdmin() || boardNameDetails?.data?.responseData?.is_owner ? <CreateEpic /> : ""}
        </div>
      </Menu>
    </>
  );
};

export default EpicButton;
