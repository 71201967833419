import React from 'react'

export default function Feeds() {
  return (
    <div>
      <h1>feeds component</h1>

      {/* <BoradHeader /> */}
    </div>
  )
}
