import React, {useState} from 'react'
import { useWorklog } from '../../../../utils/reactQuery/worklog';
import ListWorklog from './listWorklog';
import Button from '@mui/material/Button';
import './worklog.scss'
import AddWorklogCustomizedDialogs from './addWorklog/addWorklogDialog';
import Loader from '../../../../components/Loader';
import { useParams } from 'react-router-dom';
import { useBoardName } from '../../../../utils/reactQuery/boards';
import { useCard } from '../../../../utils/reactQuery/cards';
import { currentUserId, isAdmin } from '../../../../utils/constants';
import NoWorklog from './noWorklog/noWorklog';
const ShowWorklog = ({cardId, textEditor}) => {
    const [addWorklog , setAddWorklog] = useState(false)
    // const [description, setDescription] = useState(null)

    const { id } = useParams()
    const { boardName } = useBoardName(id);
    const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id
    const {
      isLoading: isCardLoading,
      card,
      isError: isCardError,
    } = useCard(cardId);
    const cardData = card?.data?.data?.responseData

    const {
        isLoading: isWorklogLoading,
        worklog,
        isError: isWorklogError,
      } = useWorklog(cardId);
      // console.log("WORKLOG",worklog)
      // console.log(worklog?.data?.data?.responseData)

      const handleAddWorklog = ()=>{
        if(isAdmin() || boardOwnerId == currentUserId() || cardData?.assigned_to_user_id == currentUserId() || cardData?.reporter == currentUserId())   
      {
        setAddWorklog(true)
      }
        
      }

      const sortWorklogsByDateDescending = (worklogs) => {
        // Check if comments array is defined and not null before sorting
        if (worklogs && worklogs.length > 0) {
          return worklogs.sort((a, b) => new Date(b.worklog_time) - new Date(a.worklog_time));
        } else {
          return []; // Return an empty array if comments are not defined
        }
      };
    
  return (
    <>
    {(!worklog?.data?.data?.responseData[0] || textEditor ) ? null : <div className='add-worklog-btn' ><Button 
    onClick={handleAddWorklog}
         sx={{
          "&:hover": {
            backgroundColor: "#1B3E61",
            border: "none",
          },
        }}
    variant="contained">Add Work Log</Button></div>}
    {/* <div className='add-worklog-btn' onClick={handleAddWorklog}><Button variant="contained">Add Work Log</Button></div> */}
    {addWorklog && <AddWorklogCustomizedDialogs cardId={cardId}  addWorklog={addWorklog} setAddWorklog={setAddWorklog} heading={"Add Work Log"} buttonName={"Save"}/>}
    { isWorklogLoading ? (<div className='worklog-loader'><Loader color={"black"}/></div>) : ( worklog?.data?.data?.responseData[0] ? 
    (sortWorklogsByDateDescending( worklog?.data?.data?.responseData).map((data) => (
        <ListWorklog data={data} cardId={cardId}/>
      ))): <NoWorklog cardId={cardId}/> )}
    </>
  )
}

export default ShowWorklog