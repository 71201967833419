import * as React from "react";
import reset, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import attachFileIcon from "../../../../styles/svg/attach-file.svg";
import childIssueIcon from "../../../../styles/svg/childIssueIcon.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BasicGrid from "./detailGrid";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import "./details.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { objectToFormData } from "../../../../utils/helpers";
import { createCardAttachment } from "../../../../sevices/apiCards";
import { useCard } from "../../../../utils/reactQuery/cards";
import Loader from "../../../../components/Loader";
import { useBoardName } from "../../../../utils/reactQuery/boards";
// import UpdateTitle from "./detailGrid/updateCardDetailTitle/UpdateCardDetailTitle"
import ChangeStatus from "./components/ChangeStatus";
import AttachFile from "./components/AttachFile";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChangeBoard from "./components/changeBoard/ChangeBoard";
import DeleteCard from "../Kanban/DeleteCard";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({
  // status,
  boardNameTitle,
  data,
  cardId,
  boardId,
  // title,
  // cardId,
  showDetails,
  setShowDetails,
  cardsOfSameWorkflowStepId,
  // description,
}) {
  const [loading, setLoading] = useState(false);
  const [checkCondition, setCheckCondition] = useState(false);
  const [addAttachment, setAddAttachment] = useState({});
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  // const [attachmentNo, setAttachmentNo] = useState(0)
  const [ishovered, setIsHovered] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log("SEARCHPARAM",searchParams)
  // const cardId = searchParams.get('card_id')
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  console.log("cardsOfSameWorkflowStepId", cardsOfSameWorkflowStepId);

  useEffect(() => {
    if (cardId && cardsOfSameWorkflowStepId.length > 0) {
      const index = cardsOfSameWorkflowStepId.findIndex(
        (item) => item.card.card_id == cardId
      );
      setCurrentCardIndex(index !== -1 ? index : null);
    }
  }, [cardId, cardsOfSameWorkflowStepId]);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // const { boardName } = useBoardName(id);
  // const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id
  // const cachedData = queryClient.getQueryData(["workflow", id]);
  // console.log("CARDSDATA",boardName)
  // const data = card?.data?.data?.responseData
  // // console.log("CARDDETAILS...",data)
  // // console.log("title",data.title);
  // const { boardName } = useBoardName(id);
  // const boardNameTitle = boardName?.data?.data?.responseData?.board_name.substring(0,3).toUpperCase()
  // console.log("boardNameTitle",boardName)

  // const { mutate: createCardAttachmentMutate } = useMutation({
  //   mutationFn: createCardAttachment,
  //   onSuccess: (res) => {
  //     queryClient.invalidateQueries({
  //       queryKey: [`cardAttachment`, cardId],
  //     });
  //     setLoading(false);
  //     reset();
  //     toast.success("Attachment added successfully");
  //     //    onClose();
  //   },
  //   onError: (error) => {
  //     console.error("Error Add Attachments", error);
  //     toast.error(error.response.data.message);
  //     setLoading(false);
  //   },
  // });

  const accessForDeleteCard =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId();

  const conditionCheck =
    isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();
  // useEffect(() => {
  //   const result = conditionCheck();
  //   setCheckCondition(result);
  //   console.log("result",result); // Logs the result to the console
  // }, [boardOwnerId, card]);

  // const handleFile=(e) => {
  //   const files = objectToFormData({
  //     card_id: cardId,
  //     // user_id: card?.data?.data?.responseData?.assigned_to_user_id,
  //     file: e.target.files[0],
  //   });
  //   setLoading(true);
  //   // setAddAttachment(files)
  //   createCardAttachmentMutate(files)
  //   // console.log("UPLOADFILE",e.target.files[0]);
  // }

  // const handleAttach =()=>{
  //   console.log("ATTACHMENT")
  //   setAddAttachment(true)
  // }

  const handlePrevious = () => {
    console.log("indexp", currentCardIndex);
    if (currentCardIndex > 0) {
      const newIndex = currentCardIndex - 1;
      console.log("indexp", newIndex);
      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleNext = () => {
    console.log("indexn", currentCardIndex, cardsOfSameWorkflowStepId.length);
    if (currentCardIndex < cardsOfSameWorkflowStepId.length - 1) {
      const newIndex = currentCardIndex + 1;
      console.log("indexn", newIndex);
      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === "ArrowLeft") {
  //       handlePrevious();
  //     } else if (event.key === "ArrowRight") {
  //       handleNext();
  //     }
  //   };

  //   window.addEventListener("keydown", (event) => handleKeyDown(event));

  //   return () => {
  //     window.removeEventListener("keydown", (event) => handleKeyDown(event));
  //   };
  // }, [handlePrevious, handleNext]);

  const removeSearchParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };
  const handleClose = () => {
    removeSearchParam("card_id");
    setShowDetails(false);
  };

  return (
    <>
      {/* <AttachFile  files={addAttachment} cardId={cardId}/> */}

      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={showDetails}
          onMouseEnter={() => setIsHovered(true)} // Set hover state
          onMouseLeave={() => setIsHovered(false)} // Reset hover state
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "calc(100vh - 30px)",
                borderRadius: "12px",
                margin: "15px auto",
                maxWidth: "1180px",
              },
            },
          }}
        >
          {isCardLoading ? (
            <div className="loader-center">
              <Loader color={"black"} />
            </div>
          ) : (
            <>
              <DialogTitle
                sx={{
                  m: 0,
                  p: 2,
                  alignItems: "center",
                  backgroundColor: "rgba(242, 246, 254, 1)",
                  borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
                }}
                id="customized-dialog-title"
                className="d-flex header"
              >
                <div className="modal-header-container">
                  <div className="modal-header-inner-container">
                    <div className="project-name d-flex">{`${card?.data?.data?.responseData?.board?.key} - ${cardId}`}</div>
                    {/* {
                     (isAdmin() || boardOwnerId == currentUserId() || card?.data?.data?.responseData?.assigned_to_user_id == currentUserId()
                     || card?.data?.data?.responseData?.reporter == currentUserId()) ?(  <ChangeStatus
                       currentStatusId={card?.data.data.responseData?.workflow_step_id}
                       cardId
                     ={cardId}/>) : (<div className="project-status d-flex justify-content-center align-items-center">
                     <button className="project-status-button">{cachedData?.data.data.responseData.status}</button>
                     <div className="project-status-icon">
                       <KeyboardArrowDownIcon />
                     </div>
                   </div>)
                   } */}
                    {card && (
                      <ChangeStatus
                        currentStatusId={
                          card?.data.data.responseData?.workflow_step_id
                        }
                        cardId={cardId}
                        checkCondition={conditionCheck}
                        card={card}
                      />
                    )}
                  </div>
                  <div className="d-flex right-heading">
                    {card && <ChangeBoard cardId={cardId} card={card} />}
                    <div className="header-attach-btn">
                      <AttachFile cardId={cardId} />
                      {/* <Button
                       variant="outlined"
                       color="primary"
                       className="white-button d-flex justify-content-center align-items-center header-attach-btn"
                       component="label"
                     >
                       <img
                         src={attachFileIcon}
                         className="header-filter-icon"
                         alt="not found"
                       />{" "}
                       &nbsp; <span className="attach-btn-content" >Attach</span>
                       <input type="file" hidden onChange={handleFile}/>
                     </Button> */}
                    </div>
                    <div>
                      {accessForDeleteCard && (
                        <DeleteCard
                          cardId={cardId}
                          boardId={boardId}
                          setShowDetails={setShowDetails}
                        />
                      )}
                    </div>
                    {/* <div className="add-child-issue-btn">
                     <Button
                       variant="outlined"
                       color="primary"
                       className="white-button "
                     >
                       <img
                         src={childIssueIcon}
                         className="header-filter-icon"
                         alt="not found"
                       />{" "}
                       &nbsp;{" "}
                       <span className="add-child-issue-btn-content">
                         Add a Child issue
                       </span>
                     </Button>
                   </div> */}
                  </div>
                </div>
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 9,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    // backgroundColor: "rgba(242, 246, 254, 1)",
                    // backgroung:'none',
                    // color: "none",
                    // transform: "none",
                    // padding: "14px"
                  },
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers>
                {/* <div className="card-title">
           <UpdateTitle title={data?.title} cardId={cardId} data={data}/>
           </div> */}
                <Typography gutterBottom>
                  <BasicGrid
                    // setIsHovered={setIsHovered}
                    description={card?.data.data.responseData?.description}
                    title={card?.data.data.responseData?.title}
                    cardId={cardId}
                    data={card?.data.data.responseData}
                  />
                </Typography>
              </DialogContent>
              {ishovered && currentCardIndex > 0 && (
                <IconButton
                  aria-label="previous"
                  onClick={handlePrevious}
                  sx={{
                    position: "absolute",
                    left: 9,
                    bottom: "50%",
                    padding: "12px",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "50%",
                    // color: (theme) => theme.palette.primary.main,
                    zIndex: 1000,
                    transition: "all 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}

              {ishovered &&
                currentCardIndex < cardsOfSameWorkflowStepId.length - 1 && (
                  <IconButton
                    aria-label="next"
                    onClick={handleNext}
                    sx={{
                      position: "absolute",
                      right: 9,
                      bottom: "50%",
                      padding: "12px",
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      borderRadius: "50%",
                      // color: (theme) => theme.palette.primary.main,
                      zIndex: 1000,
                      transition: "all 0.3s",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
            </>
          )}
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}
